/**
 * Created by Tomasz Kotlarek (ZICHER) on 03.10.2017.
 */

(function (factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module depending on jQuery.
    define('application/scrollingspy',['jquery'], factory);
  } else {
    // No AMD. Register plugin with global jQuery object.
    factory(jQuery);
  }
}(function ($) {
  $.fn.scrollingspy = function () {
    var $items = $(this);
    var $window = $(window);

    $items.each(function () {
      var $this = $(this);
      console.log("test234235-c");

      $window.on("scroll", function () {
        var topScroll = $(this).scrollTop();
        var topPosition = $this.offset().top;
        var topCenter = topPosition - ($this.height() / 2);

        console.log("test234235-d [" + topScroll + ", " + topCenter + "]");

        if (topCenter < topScroll) {
          console.log("test234235-e");
        }
      });
    });

    return this;
  };
}));
