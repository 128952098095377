/**
 * Created by Tomasz Kotlarek (ZICHER) on 11.09.2017.
 */

define(
  'application/main',[
    "application/options",
    "jquery",
    "bootstrap",
    "application/scrollingspy"
  ],
  function (
    appOptions,
    $,
    bootstrap
  ) {
    // helper variables
    var $window = $(window);
    var $document = $(document);
    var $htmlBody = $("html, body");
    var $html = $("html");
    var $body = $("body");

    // language
    var locale = $html.attr("lang");

    /**
     *
     * @type {{run: run, moveToFragment: moveToFragment, intro: intro, carousel: carousel, navigation: navigation, closePopBottom: closePopBottom, scrollSpying: scrollSpying, offices: offices}}
     */
    var application = {
      /**
       *
       * @returns {application}
       */
      run: function () {
        this
          .moveToFragment()
          .intro()
          .carousel()
          .navigation()
          .closePopBottom()
          .scrollSpying()
          .offices();

        $document.trigger("domset", [{dom: $body}]);

        return this;
      },

      /**
       *
       * @returns {application}
       */
      moveToFragment: function () {
        $document.on("moveToFragment", function (event, data) {
          setTimeout(function () {
            var offset = data.$target.offset();

            if (offset) {
              // minus fixed menu height minus target top margin
              var height = 0; //$(".js-move-to-fragment-header").height();
              var margin = 0; //parseInt(window.getComputedStyle(document.getElementById($target.attr("id"))).getPropertyValue("margin-top"));
              var scrollTo = offset.top - height - margin;

              $("html, body").animate({scrollTop: scrollTo}, data.source === "load" ? 0 : 500);
            }
          }, 20);
        });

        $document.on("click", 'a[href*="#"]', function (event) {
          event.preventDefault();
          var $this = $(this);
          var href = $this.attr("href");
          var hash = href.substr(href.indexOf("#"));
          history.pushState(null, $this.text(), hash);

          $document.trigger("moveToFragment", [{
            source: "click",
            $target: $(hash)
          }]);
        });

        $document.trigger("moveToFragment", [{
          source: "load",
          $target: $(":target")
        }]);

        return this;
      },

      /**
       *
       * @returns {application}
       */
      intro: function () {
        $document.on("domset", function (event, data) {
          var $video = data.dom.find(".js-top-video");

          if ($video.length === 0) {
            return;
          }

          $video.on("ended", function () {
            $(".js-menu, .js-top-poster").fadeIn(200, function () {
              $(this).addClass("visible");
            });

            $(".js-pop-bottom").addClass("visible");
          });

          var isVideoPlaying = setInterval(function () {
            var video = $video[0];

            if (video.currentTime > 0) {
              clearInterval(isVideoPlaying);

              video.currentTime = 0;
              video.play();

              var loaderClass = "video-loader";

              $video
                .css("opacity", "1")
                .closest("." + loaderClass)
                .removeClass(loaderClass);
            }

            if ($video[0].ended) {
              $video.trigger("ended");
            }
          }, 100);
        });

        return this;
      },

      /**
       *
       * @returns {application}
       */
      carousel: function () {
        $document.on("domset", function (event, data) {
          data.dom.find(".js-owl-carousel").each(function () {
            var $this = $(this);

            require(["owl-carousel"], function () {
              $this.owlCarousel($.extend({}, appOptions.owlCarousel, $this.data("owl-carousel")));
            });
          });
        });

        return this;
      },

      /**
       *
       * @returns {application}
       */
      navigation: function () {
        /*console.log("test234235-a");

        $document.on("domset", function (event, data) {
          console.log("test234235-b");

          $(".slides__item").on("scrollspyenter", function() {
            console.log("test2323");
            console.log($(this).attr("class"));
          }).scrollingspy();
        });*/

        return this;
      },

      /**
       *
       * @returns {application}
       */
      closePopBottom: function () {
        $document.on("domset", function (event, data) {
          data.dom.find(".js-pop-bottom-close").on("click", function (event) {
            event.preventDefault();

            var $this = $(this);

            $this.closest(".js-pop-bottom").fadeOut(function () {
              require(["js-cookie"], function (cookies) {
                cookies.set("pop-bottom", true, {expires: 365});
              });
            });
          });
        });

        return this;
      },

      /**
       *
       * @returns {application}
       */
      scrollSpying: function () {
        var $window = $(window);

        // TODO switch CSS classes to js-*
        $document.on("domset", function (event, data) {
          data.dom.find(".slides__item").each(function (index) {
            var $this = $(this);
            var $navigation = $(".slides__navigation__item").eq(index).find("a");
            var width = $window.width();
            var parameters = {
              offset: $this.offset().top,
              scroll: 0,
              height: $this.height()
            };

            $window.on("resize", function () {
              width = $window.width();
            });

            $window.on("scroll", function () {
              if (width < 992) {
                return false;
              }

              parameters.scroll = $window.scrollTop();

              if (parameters.scroll + (parameters.height / 2) > parameters.offset
                && parameters.scroll + (parameters.height / 2) <= parameters.offset + parameters.height
              ) {
                if (!$navigation.hasClass("active")) {
                  $navigation.addClass("active");

                  var href = $navigation.attr("href");
                  var hash = href.substr(href.indexOf("#"));
                  history.pushState(null, $navigation.text(), hash);
                }
              } else {
                if ($navigation.hasClass("active")) {
                  $navigation.removeClass("active");
                }
              }
            });
          });
        });

        return this;
      },

      /**
       *
       * @returns {application}
       */
      offices: function () {
        //var googlemapsloadedInitialize = function () { console.error("Not yet initialized")};

        $document.on("domset", function (event, data) {
          var items = [];
          var map;
          var $map = data.dom.find(".js-map");

          data.dom.find(".js-office").on("click", function (event) {
            event.stopImmediatePropagation();

            var $this = $(this).toggleClass("active");

            setTimeout(function () {
              $document.one("click", function () {
                $this.removeClass("active");
              });
            }, 10);
          });

          data.dom.find(".js-office-item").on("click", function (event) {
            event.stopImmediatePropagation();

            $document.trigger("click");

            var $this = $(this);
            var data = $this.data("office");

            $(".js-office-name").text(data.name);
            $(".js-office-open-hours").html(data.openHours.replace(/\n/g, "<br/>"));
            $(".js-office-address").html(data.address.replace(/\n/g, "<br/>"));
            $(".js-office-email").html(data.email).attr("href", "mailto:" + data.email);
            $(".js-office-phone").html(data.phone).attr("href", "tel:" + data.phone);

            map.setCenter(new google.maps.LatLng(data.latitude, data.longitude));

            if ($window.width() >= 1200) {
              map.panBy(-240, -100);
            }
          }).each(function () {
            items.push($(this).data("office"));
          });

          var googlemapsloadedInitialize = function () {
            var options = {
              draggable: true,
              panControl: false,
              streetViewControl: false,
              scrollwheel: true,
              scaleControl: false,
              disableDefaultUI: true,
              disableDoubleClickZoom: true,
              gestureHandling: "greedy",
              zoom: 16,
              center: new google.maps.LatLng(items[0].latitude, items[0].longitude)
            };

            map = new google.maps.Map($map[0], options);

            if ($window.width() >= 1200) {
              map.panBy(-240, -100);
            }

            for (var i = 0; i < items.length; i++) {
              new google.maps.Marker({
                position: new google.maps.LatLng(items[i].latitude, items[i].longitude),
                map: map,
                icon: "/frontend/images/map-pin.svg"
              });
            }
          };

          if (typeof googlemapsloaded !== "undefined" && googlemapsloaded === true) {
            googlemapsloadedInitialize();
          } else {
            $document.on("googlemapsloaded", function () {
              googlemapsloadedInitialize();
            });
          }

          $("<div class=\"map-contact__activate js-map-activate\"></div>")
            .attr("data-map-activate", appOptions.map.activate)
            .on("click", function () {
              $(this).fadeOut();
            })
            .appendTo($map);
        });

        return this;
      }
    };

    application.run();

    return application;
  }
);
