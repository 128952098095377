/**
 * Created by Tomasz Kotlarek (ZICHER) on 28.08.2017.
 */

require.config({
  baseUrl: "/frontend/js",
  paths: {
    //"propertyParser": "../../bower_components/requirejs-plugins/src/propertyParser",
    //"async": "../../bower_components/requirejs-plugins/src/async",
    //"goog": "../../bower_components/requirejs-plugins/src/goog",
    "jquery": [
      "//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min",
      "vendor/jquery/jquery-3.2.1.min"
    ],
    "bootstrap": [
      "//cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/js/bootstrap.min",
      "vendor/bootstrap/bootstrap-3.3.7.min"
    ],
    "owl-carousel": [
      "//cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.2.1/owl.carousel.min",
      "vendor/owl-carousel/owl.carousel-2.2.1.min"
    ],
    "js-cookie": "vendor/js-cookie/js-cookie-2.1.4.min"
  },
  shim: {
    "jquery": {
      exports: "jQuery"
    },
    "bootstrap": ["jquery"],
    "owl-carousel": ["jquery"]
  }
});

require(["application/main"]);
define("main", function(){});

