/**
 * Created by Tomasz Kotlarek (ZICHER) on 11.09.2017.
 */

define('application/options',[],function () {
  return {
    map: {
      activate: "Kliknij, aby aktywować nawigowanie"
    },
    owlCarousel: {
      loop: true,
      //autoplay: true,
      autoplay: false,
      autoplayHoverPause: true,
      dots: false,
      nav: true,
      //autoWidth: true,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 2,
          margin: 85,
          //autoWidth: true
        },
        1200: {
          items: 4,
          margin: 85,
          //autoWidth: true
        }
      },

      // TODO move to HTML?
      navText: [
        "<span class='hidden-lg hidden-xl sprites svg-arrow-orange-mobile partners__carousel__navigation partners__carousel__navigation--previous'></span><span class='hidden-xs hidden-sm hidden-md sprites svg-arrow-orange-desktop partners__carousel__navigation partners__carousel__navigation--previous'></span>",
        "<span class='hidden-lg hidden-xl sprites svg-arrow-orange-mobile partners__carousel__navigation partners__carousel__navigation--next'></span><span class='hidden-xs hidden-sm hidden-md sprites svg-arrow-orange-desktop partners__carousel__navigation partners__carousel__navigation--next'></span>"
      ]
    }
  }
});
